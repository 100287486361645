<template>
  <v-card>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template #activator="{ on }">
        <v-text-field
          v-model="month"
          label="日付"
          prepend-icon="event"
          v-on="on"
        />
      </template>
      <v-date-picker v-model="month" type="month" @input="menu = false" />
    </v-menu>
    <span v-if="downloadedCount"> {{ downloadedCount }} 件取得 </span>
    <v-card-actions>
      <v-spacer />
      <v-btn color="error" :disabled="!downloading" @click="abort = true">
        中断
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!month"
        :loading="downloading"
        @click="getCSV"
      >
        出力
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment'
const DATA_PROPS = [
  { text: '予約登録店舗コード', prop: 'prevShopCode' },
  { text: '予約登録店舗名称', prop: 'prevShopName' },
  { text: '来店店舗コード', prop: 'shopCode' },
  { text: '来店店舗名称', prop: 'shopName' },
  { text: '来店日', prop: 'date' },
  {
    text: '予約登録日時',
    prop: 'registered',
    transform: v => moment(v).format('YYYY-MM-DD HH:mm'), // NOTE: JSTで日時は返してるはず
  },
  { text: '予約ステータス', prop: 'state' },
  { text: '予約経路', prop: 'reservingWay' },

  { text: '予約対応スタッフコード', prop: 'attendedStaffCode' },
  { text: '予約対応スタッフ名', prop: 'attendedStaffName' },
  { text: '前回塗布スタッフコード', prop: 'dyeingStaffCode' },
  { text: '前回塗布スタッフ名', prop: 'dyeingStaffName' },

  { text: '予約者セイ', prop: 'reserverFamilyNameKana' },
  { text: '予約者メイ', prop: 'reserverGivenNameKana' },
  { text: '顧客番号', prop: 'customerNumber' },
  { text: '顧客セイ', prop: 'customerFamilyNameKana' },
  { text: '顧客メイ', prop: 'customerGivenNameKana' },
]

export default {
  name: 'KPIMonthly',
  data: () => ({
    menu: false,
    month: moment()
      .subtract(1, 'month')
      .format('YYYY-MM'),
    downloading: false,
    abort: false,
    downloadedCount: 0,
  }),
  methods: {
    async getCSV() {
      try {
        let month = this.month
        this.downloading = true
        let rows = await this.request([DATA_PROPS.map(x => x.text)])
        if (this.abort) {
          this.$toast.info('中断しました。')
        } else if (rows.length <= 1) {
          // NOTE: ヘッダで1行は必ずある
          this.$toast.error('データがありませんでした。')
        } else {
          this.dl(`kpi-montyly-${month}.csv`, rows)
        }
      } catch (err) {
        console.error(err)
        this.$toast.error('エラーが発生しました。')
      }
      this.downloading = false
      this.abort = false
      this.downloadedCount = 0
    },
    async request(r, url = null) {
      let { results, next } = await (url
        ? this.$api.http.get(url)
        : this.$api.kpis.monthly(this.month))
      if (this.abort) return
      results = results.map(data =>
        DATA_PROPS.map(x => this.transform(data, x))
      )
      r = r.concat(results)
      this.downloadedCount = r.length - 1
      if (next) return await this.request(r, next)
      else return r
    },
    transform(data, { prop, transform }) {
      let rval = data[prop] || ''
      if (transform) {
        rval = transform(rval)
      }
      return `"${rval}"`
    },
    dl(filename, rows) {
      rows = rows.map(row => row.join(','))
      let content = rows.join('\r\n')
      let bom = new Uint8Array([0xef, 0xbb, 0xbf])
      let blob = new Blob([bom, content], { type: 'text/csv' })

      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        let a = document.createElement('a')
        a.download = filename
        // a.target = '_blank'
        a.href = window.URL.createObjectURL(blob)

        // firefox対策． chromeの場合は append/remove をしなくていいらしい
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
  },
}
</script>
